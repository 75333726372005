import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "348px",
    height: 140,
    background: "#FFFFFF",
    borderRadius: "10px",
    "& .MuiDialogContent-root": {
      flex: "none !important",
    },
    "& .MuiDialogActions-root": {
      marginRight: "0px !important",
    },
"& .MuiDialogTitle-root": {
  "@media (max-width: 600px)": {
    padding: "8px 24px",
  },
},
  },
  Titlemain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "23.989px",
    lineHeight: "36px",
    color: "#000000",
    marginTop: "16px",
  },
  subMain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "15px",
    textAlign: "center",
    color: "#000000",
    "@media (max-width: 600px)": {
      lineHeight: "22px",
    },
  },
  dialogContent: {
    "& .MuiDialogContent-root": {
      padding: "0px !important  ",
    },
  },
  yesButton: {
    width: "92px",
    height: "35px",
    background: "#FFBF00",
    // borderRadius: "10px",

    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "15px",
    textAlign: "center",
    color: "#FFFFFF",
    "&:hover": {
      border: "2px solid #FFBF00",
      background: "#333",
      color: "#fff",
    },
  },
  NoButton: {
    width: "92px",
    height: "35px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "15px",
    textAlign: "center",
    background: "transparent", 
    border: "1px solid #FFBF00", 
    color:"#FFBF00",
    "&:hover": {
      border: "2px solid #FFBF00",
      background: "#333",
      color: "#fff",
    },
  }
}));
export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        PaperProps={{
          classes: {
            root: classes.root,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {/* <Typography className={classes.Titlemain}> {title}</Typography> */}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              textAlign: "center",
              fontSize: "17px",
            }}
          >
            <Typography
              style={{ fontSize: "18px" }}
              className={classes.subMain}
            >
              {" "}
              {desc}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0 22px", }}>
        <Button
            className={classes.yesButton}
            onClick={() => {
              handleClose();
              confirmationHandler();
            }}
          >
            LOGOUT
          </Button>
          <Button
            className={classes.NoButton}
            onClick={handleClose}
            autoFocus
          >
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
